import React, { useState, useEffect, useMemo } from 'react';
import { useLoaderData,Link } from 'react-router-dom';
import MetaData from '../components/UI/MetaData';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import { Box, Chip, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslationsContext } from '../store/context/translations-context';
import { fetchProfileCompleteness } from '../utils/cms';
import AlertsContainer from '../components/UI/AlertMessage/AlertsContainer';
import ProfileProgressBar from '../components/ProfileProgressBar/ProfileProgressBar';
import Calendar from '../components/Calendar/Calendar';
function Home() {
  const loaderData = useLoaderData();
  const meta = loaderData?.meta || "";
  const theme = useTheme();
  const [profileCompletenessItems, setProfileCompletenessItems] = useState([]);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [isChipLoading, setIsChipLoading] = useState(true); // Loading state for Chip components

  const [translatedLabels, setTranslatedLabels] = useState({
    seguros: '',
    creditos: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const seguros = await requestTranslation('insurance');
      const creditos = await requestTranslation('credits');

      setTranslatedLabels({
        seguros,
        creditos
      });

      setIsChipLoading(false); // Mark chip translations as loaded
    };


    translateLabels();
  }, [requestTranslation]);

  useEffect(() => {
    async function loadData() {
      const profileCompletenessData = await fetchProfileCompleteness();
      setProfileCompletenessItems(profileCompletenessData || []);
    }

    loadData();
  }, []);

  const memoizedProfileCompletenessItems = useMemo(() => profileCompletenessItems, [profileCompletenessItems]);
  const issuesCount = memoizedProfileCompletenessItems?.steps?.length || 0;

  return (
    <React.Fragment>
      <MetaData data={JSON.stringify(meta)} />

      <div className='flex flex-col gap-16 '>
        {memoizedProfileCompletenessItems.percentage && <ProfileProgressBar progress={memoizedProfileCompletenessItems.percentage} />}

        {/* Alerts Container */}
        {memoizedProfileCompletenessItems.steps && <AlertsContainer issuesCount={issuesCount} steps={memoizedProfileCompletenessItems.steps || []} />}

        {/* Render Chips with loading spinner */}
        <Box display="flex" justifyContent="center" gap={1}>
          {isChipLoading ? (
            <CircularProgress />
          ) : (
            <>
            <Link to={"/pages/my-insurances"}>
              <Chip
                sx={{
                  fontSize: '1rem',
                  padding: '0.5rem',
                }}
                clickable
                label={translatedLabels.seguros}
                size="medium"
                variant="outlined"
              />
              </Link>
              <Link to={"/pages/my-credits"}>
              <Chip
                sx={{
                  fontSize: '1rem',
                  padding: '0.5rem',
                }}
                clickable
                label={translatedLabels.creditos}
                size="medium"
                variant="outlined"
              />
                  </Link>
            </>
          )}
        </Box>
        <Calendar
        initialView="listWeek" // Define the initial view
        views={[ 'listWeek']} // Define the available views
      />
        <Chat />

        <Swiper />
        <ContentPage />
      </div>
    </React.Fragment>
  );
}

export default Home;
