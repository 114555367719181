import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { useTranslationsContext } from '../../store/context/translations-context';

const ProfileProgressBar = ({ progress = 60 }) => {
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [translatedText, setTranslatedText] = useState({
    completeProfile: '',
  });
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const translateLabels = async () => {
      const completeProfile = await requestTranslation('complete-your-profile');
      setTranslatedText({ completeProfile });
      setIsLoading(false); // Set loading to false after translation is fetched
    };


    translateLabels();
  }, [requestTranslation]);

  // Show a loading spinner while translations are being fetched
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Link to={"profile/my-profile"} style={{ textDecoration: 'none' }}>
      <Box className="flex flex-col items-start gap-2 w-full" sx={{ position: 'relative' }}>
        <Typography className='text-primary-main underline' variant="subtitle2">
          {translatedText.completeProfile}
        </Typography>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: '100%', height: "1.5rem" }}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'rgb(0, 255, 58)', // Completed progress color
              },
              backgroundColor: 'rgb(174, 100, 100)', // Not completed progress color
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              bottom: '-1.5rem',
              right: 0,
              color: 'text.secondary'
            }}
          >
            {`${progress}%`}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default ProfileProgressBar;
