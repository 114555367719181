// ** React Imports
import React, { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// ** Third Party Components
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import { Link } from 'react-router-dom'
// ** Keen Slider CSS Import
import 'keen-slider/keen-slider.min.css';

const Swiper = ({ direction = 'ltr', autoplay = 12000 }) => {
  // ** States
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // ** Hook
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    rtl: direction === 'rtl',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created(slider) {
      setLoaded(true)
      setTotalSlides(slider.track.details.slides.length)
    },
    slides: {
      perView: 1.1, // Show a portion of the next slide
      spacing: 2,  // Add spacing between slides
    },
  })

  // Auto-switching effect
  useEffect(() => {
    if (instanceRef.current) {
      const timer = setInterval(() => {
        instanceRef.current.next()
      }, autoplay)
      return () => clearInterval(timer)
    }
  }, [instanceRef, autoplay])

  return (
    <Box className="flex flex-col gap-4 ">
    <Box className="flex flex-col gap-8">
      <Box className='navigation-wrapper w-full sm:w-1/2 mx-auto' sx={{ position: 'relative' }}>
        <Box ref={sliderRef} className='keen-slider rounded-lg items-center'>
          <Link to={"https://docs.p4lpro.com/cards/dap"}>
            <Box className='keen-slider__slide'>
              <img
                src='/static/images/swiper/creditos.png'
                alt='swiper 1'
                style={{ width: '100%', height:'auto', objectFit: 'cover' }}  // Ensure images fit well inside
              />
            </Box>
          </Link>
          <Link to={"https://docs.p4lpro.com/cards/demo"}>
            <Box className='keen-slider__slide'>
              <img
                src='/static/images/swiper/gestao-patrimonio.png'
                alt='swiper 2'
                style={{ width: '100%', height:'auto', objectFit: 'cover' }}  // Ensure images fit well inside
              />
            </Box>
          </Link>
          <Box className='keen-slider__slide'>
            <img
              src='/static/images/swiper/rendimentos.png'
              alt='swiper 3'
              style={{ width: '100%', height:'auto', objectFit: 'cover' }}  // Ensure images fit well inside
            />
          </Box>
          <Box className='keen-slider__slide'>
            <img
              src='/static/images/swiper/saude-financeira.png'
              alt='swiper 4'
              style={{ width: '100%', height:'auto', objectFit: 'cover' }}  // Ensure images fit well inside
            />
          </Box>
          <Box className='keen-slider__slide'>
            <img
              src='/static/images/swiper/seguros.png'
              alt='swiper 5'
              style={{ width: '100%', height:'auto', objectFit: 'cover' }}  // Ensure images fit well inside
            />
          </Box>
        </Box>


      </Box>
    </Box>
    <Box
              className="dots w-4/5 sm:w-1/2 mx-auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',


              }}
            >
              {[...Array(totalSlides)].map((_, idx) => (
                <Box
                  key={idx}
                  onClick={() => instanceRef.current?.moveToIdx(idx)}
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: currentSlide === idx ? '#000' : '#ddd',
                    margin: '0 5px',
                    cursor: 'pointer',
                  }}
                ></Box>
              ))}
            </Box>


    </Box>
  )
}

export default Swiper
