import React, { useState, useEffect } from "react";
import { Box, Grid, CircularProgress, FormControl, Typography } from "@mui/material";
import Input from "../UI/Input"; // Custom input component
import Form from "../UI/Form"; // Reusable form component
import { financeAtCheck, updateFinancePassword } from "../../utils/cms"; // API functions
import { useNavigate } from "react-router-dom"; // For navigation
import { useTranslationsContext } from "../../store/context/translations-context";

const FinancialStatusInvalid = ({ translatedLabels, instructions, disclaimer }) => {
  const [fieldValues, setFieldValues] = useState({
    nif: "",
    financialPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const [isTranslating, setIsTranslating] = useState(true); // Loading state for translations
  const [translatedErrorMessage, setTranslatedErrorMessage] = useState(""); // State for translated error message
  const navigate = useNavigate(); // For navigating to the notification page

  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  // Fetch the translation for the error message
  useEffect(() => {
    const fetchTranslation = async () => {

      try {
        const translatedText = await requestTranslation('credentials-error'); // Assuming 'credentials-error' is the translation key
        setTranslatedErrorMessage(translatedText);
      } catch (error) {
        console.error('Translation error:', error);
      } finally {
        setIsTranslating(false); // Stop translation loading
      }
    };

    fetchTranslation();
  }, [requestTranslation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { nif, financialPassword } = fieldValues;

    setIsLoading(true); // Start loading

    try {
      // First, update the financial password
      const updatePasswordResponse = await updateFinancePassword(nif, financialPassword);

      // Check if the password update was successful
      if (updatePasswordResponse.ok) {
        // Call financeAtCheck after successfully updating the password
        const atCheckResponse = await financeAtCheck(nif);

        // Handle success if financeAtCheck succeeds
        if (atCheckResponse.ok) {
          // Navigate to the notification page after both APIs succeed
          navigate("/notification", {
            state: {
              messages: {
                title: "finance-password-update",
                success: 'notification-finance-password-update-success',
                instructions: "notification-finance-password-update-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/profile/financials",
                },
              ],
            },
          });
        } else {
          throw new Error(atCheckResponse.message || "Failed to check status.");
        }
      } else {
        throw new Error(updatePasswordResponse.message || "Failed to update the password.");
      }
    } catch (error) {
      // Navigate to notification page in case of failure
      navigate("/notification", {
        state: {
          messages: {
            title: "finance-password-update",
            error: 'notification-finance-password-update-error',
            instructions: "notification-finance-password-update-error-instructions",
          },
          buttons: [
            {
              text: "try-again",
              link: "/profile/financials",
            },
          ],
        },
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  if (isTranslating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Form buttonText={translatedLabels.save} onSubmit={handleSubmit} isLoading={isLoading}>
      <p className="mb-6">{instructions}</p>
      <p className="mb-6">{translatedErrorMessage}</p> {/* Display the translated error message */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="number"
              label={translatedLabels.nif}
              name="nif"
              value={fieldValues.nif}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="password"
              label={translatedLabels.financialPassword}
              name="financialPassword"
              value={fieldValues.financialPassword}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Typography className="mt-6" style={{ color: "#d32f2f", fontWeight: "bold" }}>{disclaimer}</Typography>
    </Form>
  );
};

export default FinancialStatusInvalid;
