import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const EventDetails = ({ event }) => {
  const renderMetadata = (metaData) => {
    if (!metaData) return <Typography>No metadata available.</Typography>;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>Metadata</Typography>
        <Grid container spacing={2}>
          {Object.entries(metaData).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <Card sx={{ backgroundColor: '#f5f5f5' }}>
                <CardContent>
                  <Typography variant="body2" color="textSecondary">{key}</Typography>
                  <Typography variant="body1">{value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      {/* Event Title */}
      <Typography variant="h5" gutterBottom>{event.title}</Typography>

      {/* Event Start and End Times */}
      <Box display="flex" alignItems="center" mb={2}>
        <EventIcon sx={{ mr: 1 }} />
        <Typography variant="body1" color="textSecondary">
          {new Date(event.start).toLocaleString()} - {event.end ? new Date(event.end).toLocaleString() : 'No end time'}
        </Typography>
      </Box>

      {/* Event Description */}
      <Box display="flex" alignItems="center" mb={2}>
        <DescriptionIcon sx={{ mr: 1 }} />
        <Typography variant="body1" color="textSecondary">{event.extendedProps.description || 'No description available'}</Typography>
      </Box>

      {/* Divider */}
      <Divider sx={{ my: 2 }} />

      {/* Metadata Section */}
      {renderMetadata(event.extendedProps.metaData)}
    </Box>
  );
};

export default EventDetails;
